import api, { headers } from "../../services/api";

export const getSurveyByIdAPI = async (surveyid) => {
  return api.get(`survey/${surveyid}/build`, { headers });
};

export const updateSurveyByIdAPI = async (surveyid, surveyLocal) => {
  return api.put(`survey/${surveyid}/build`, surveyLocal, { headers });
};

export const getStyleByIdSurveyAPI = async (surveyid) => {
  return api.get(`styles/${surveyid}`, { headers });
};

export const getLinksByIdSurveyAPI = async (surveyid) => {
  return api.get(`survey/${surveyid}/share`, { headers });
};

export const createSubLinkAPI = async (surveyid) => {
  return api.post(`survey/${surveyid}/share`, {}, { headers });
};

export const updateLinkAPI = async (surveyid, shareid, data) => {
  return api.put(`survey/${surveyid}/share/${shareid}`, data, { headers });
};

export const downloadFileDocxAPI = async (surveyid) => {
  return api.get(`survey/${surveyid}/export_survey`, { headers });
};
